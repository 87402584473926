.Toolbar {
  height: 68px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(186, 222, 233);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 50;
}

.Toolbar nav {
  height: 100%;
}

.LogoHolder {
  height: 90%;
  margin-left: 2rem;
}

.Logo {
  background-color: white;
  padding: 0.125rem;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.LogoHolder img {
  height: 100%;
}

.Announcement {
  width: 100vw;
  height: fit-content;
  background-color: red;
}

@media (max-width: 992px) {
  .DesktopOnly {
    display: none;
  }
}
