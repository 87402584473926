.NavigationItem,
.SideBarItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a,
.SideBarItem a {
  color: #000;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active,
.SideBarItem a:hover,
.SideBarItem a:active,
.SideBarItem a.active {
  color: rgb(71, 175, 255);
}

.SideBarItem {
  text-align: center;
}

.SideBarItem a {
  font-weight: bold;
}

@media (max-width: 992px) {
  .SideBarItem {
    border-right: 1px solid rgb(186, 222, 233);
    border-left: 1px solid rgb(186, 222, 233);
  }
}

@media (min-width: 992px) {
  .NavigationItem {
    margin: 0 0.125rem;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavigationItem a {
    color: rgb(67, 67, 67);
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    display: flex;
    align-items: center;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: rgb(71, 175, 255);
    border-bottom: 4px solid rgb(125, 156, 128);
    color: white;
  }
}
