.Button {
  appearance: button;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  box-sizing: border-box;
  margin: 0 0.125rem;
  font: 400 13.3333px Arial;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  text-transform: none;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Button:disabled {
  opacity: 65%;
}

.Success {
  color: rgb(67, 67, 67);
  background-color: transparent;
  background-image: none;
  border-color: rgb(186, 222, 233);
  cursor: pointer;
}

.Success:hover {
  color: #fff;
  background-color: rgb(71, 175, 255);
  border-color: rgb(186, 222, 233);
}

.Success:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.Success:disabled {
  color: #007bff;
  cursor: not-allowed;
  background-color: transparent;
}

.Cancel {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
  cursor: pointer;
}

.Cancel:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.Cancel:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.Cancel:disabled {
  color: #6c757d;
  cursor: not-allowed;
  background-color: transparent;
}
