.Spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid rgb(186, 222, 233);
  margin: auto;
  position: relative;
  z-index: 1;
}

.Spinner:before {
  content: "";
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 5px solid rgb(125, 156, 128);
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  z-index: 2;
  top: -5px;
  left: -5px;
  animation: Spin 1s infinite linear;
}

@media (min-width: 992px) {
  .Spinner {
    width: 200px;
    height: 200px;
    border: 10px solid rgb(186, 222, 233);
  }

  .Spinner:before {
    width: 200px;
    height: 200px;
    border-top: 10px solid rgb(125, 156, 128);
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    top: -10px;
    left: -10px;
  }
}

@keyframes Spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Spinner > .Logo {
  text-align: center;
  display: block;
  margin: 20% auto;
  height: 65%;
}

.Searching:after {
  content: " .";
  animation: Dots 1.2s steps(5, end) infinite;
  font-size: 30px;
  color: rgb(125, 156, 128);
  font-weight: 700;
}

.Searching {
  font-size: 30px;
  color: rgb(186, 222, 233);
  font-weight: 700;
  text-align: center;
  margin: 0.125rem;
}

@keyframes Dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(125, 156, 128);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 rgb(125, 156, 128), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(125, 156, 128), 0.5em 0 0 rgb(125, 156, 128);
  }
}
