.SideDrawer {
  position: fixed;
  width: 55%;
  max-width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.4s ease-out;
  text-align: center;
}

.LogoHolder img {
  height: 60%;
}

@media (min-width: 762px) {
  .LogoHolder img {
    height: 70%;
  }
}

@media (min-width: 992px) {
  .SideDrawer {
    display: none;
  }

  .LogoHolder img {
    height: 100%;
  }
}

.Open {
  transform: translateX(0);
}

.Closed {
  transform: translateX(-100%);
}

.LogoHolder {
  height: 20%;
  margin-bottom: 32px;
}

.Logo {
  background-color: white;
  padding: 0.125rem;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}
