body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  height: 1px;
  background-color: rgb(186, 222, 233);
  border: none;
}

h1 {
  font-size: 36px;
  line-height: 48px;
}

h2 {
  font-size: 30px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 34px;
}

h4 {
  font-size: 20px;
  line-height: 30px;
}

h5 {
  font-size: 16px;
  line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(71, 175, 255);
  text-transform: capitalize;
}

.redText {
  color: rgb(125, 156, 128);
}

table {
  border-collapse: collapse;
}

*,
::after,
::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 13px;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f100;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
  border: 1px solid rgb(177, 177, 177);
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
  border: 1px solid rgb(0, 0, 0);
}

.Scroll {
  overflow-y: scroll;
}

.Telephone {
  /* font-family: "Cormormant Infant", Helvetica, Arial, Verdana, san-serif; */
  font-weight: bold;
  /* padding-left: 15px; */
}

.Telephone a {
  text-decoration: none;
  color: #006bb7;
}
