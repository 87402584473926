.Footer {
  flex-shrink: 0;
  height: 68px;
  width: 100%;
  background-color: rgb(186, 222, 233);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 50;
  flex-wrap: wrap;
}

.Center {
  text-align: center;
}

.Information {
  color: rgb(67, 67, 67);
  justify-content: center;
}
